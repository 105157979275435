import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
export const exportExcel = (sheetData, title) => {

    console.log('sheetData',sheetData);

const sheet = XLSX.utils.aoa_to_sheet(sheetData);

// sheet['!merges'] = [
//     { s: { r: 9, c: 0 }, e: { r: 9, c: 1 } } // Merge cells for "Weight" and "Result"
// ];

const numberOfColumns = sheetData[0].length; // Assuming first row determines the number of columns
const columnWidth = 100 / numberOfColumns;

// Set column widths
sheet['!cols'] = Array(numberOfColumns).fill({ width: columnWidth });

const workbook = {
    Sheets: { 'Sheet1': sheet },
    SheetNames: ['Sheet1']
};

const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
saveAs(data, `${title}.xlsx`);
}
